import { createEffect, Show, createSignal, createUniqueId } from 'solid-js';
import { Button, Errors, FieldDescription, Form, HiddenFields, Input, Label, TextField } from '@troon/ui';
import { useAction, useSubmission } from '@solidjs/router';
import { useTrackEvent } from '@troon/analytics';
import { useUtmParams } from '../../../../providers/utm';
import type { Action } from '@solidjs/router';
import type { OperationResult, TypedDocumentNode } from '@urql/core';

type Rec = Record<string, unknown>;
type Mutation = { __typename?: string; [key: string]: unknown };

type Props<R extends Rec = Rec, V extends Rec = Rec, M extends Mutation = Mutation> = {
	promoCode?: string;
	action: Action<[FormData], OperationResult<M, R> | void>;
	document: TypedDocumentNode<M, V>;
	intentData?: Record<string, unknown>;
	onUpdatePromoCode: (code?: string) => void;
};

export function PromoCodeEntry(props: Props) {
	const utm = useUtmParams();
	const [showPromoApplied, setShowPromoApplied] = createSignal(!!props.promoCode);
	const [promoRef, setPromoRef] = createSignal<HTMLInputElement>();
	const [formRef, setFormRef] = createSignal<HTMLFormElement>();
	const id = createUniqueId();
	const trackEvent = useTrackEvent();

	const submit = useAction(props.action);
	const submission = useSubmission(props.action, ([data]: [FormData]) => data.get('__formId') === id);

	createEffect(() => {
		if (submission.result?.data?.subscription && !submission.result?.error) {
			trackEvent('applyPromoCode');
			setShowPromoApplied(!!props.promoCode);
		}
	});

	const clearPromoCode = () => {
		props.onUpdatePromoCode && props.onUpdatePromoCode('');
		submission.clear();
		setShowPromoApplied(false);
		const ref = promoRef();
		if (ref) {
			ref.value = '';
		}
		const data = new FormData(formRef());
		submit(data);
	};

	return (
		<Form ref={setFormRef} id={id} action={props.action} document={props.document}>
			<HiddenFields data={{ ...props.intentData, utm: utm() }} />
			<TextField name="promoCode">
				<Label suppressRequired>
					<Show when={!showPromoApplied()} fallback="Change promo code">
						Have a promo code?
					</Show>
				</Label>
				<Input
					ref={setPromoRef}
					class="uppercase"
					placeholder="CODE"
					onInput={(e) => {
						const value = e.currentTarget.value;
						if (!value) {
							setShowPromoApplied(false);
						} else {
							submission.clear();
						}
						props.onUpdatePromoCode && props.onUpdatePromoCode(value);
					}}
					onClick={(e) => {
						e.currentTarget.select();
						setShowPromoApplied(false);
					}}
					onBlur={(e) => {
						if (e.currentTarget.value === '' && submission.result?.error) {
							clearPromoCode();
						}
					}}
					value={props.promoCode ?? ''}
					suffixElement={
						showPromoApplied() || submission.result?.error ? (
							<Button
								class="pointer-events-auto normal-case"
								type="button"
								appearance="transparent"
								size="sm"
								onClick={clearPromoCode}
							>
								Clear
							</Button>
						) : (
							<Button class="pointer-events-auto normal-case" type="submit" appearance="transparent" size="sm">
								Apply
							</Button>
						)
					}
				/>
				<FieldDescription>
					<Show when={showPromoApplied()} fallback={<p>Enter your promo code and hit “Apply”.</p>}>
						<p class="text-green-600">Promo code applied!</p>
					</Show>
				</FieldDescription>
			</TextField>

			<Errors />
		</Form>
	);
}
